.wrap {
  text-align: center;
  padding: 64px 0;
  height: 100%;
  background: #fffbed;
  min-height: calc(100vh - 93px - 137px);
}

@media (min-width: 768px) {
  .wrap {
    min-height: calc(100vh - 93px - 81px);
  }
}

.sub {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  margin-bottom: 32px;
}

.title {
  font-family: "AndesBold";
  font-weight: normal;
  font-size: 34px;
  margin: 0;
  margin-bottom: 32px;
}

@media (min-width: 600px) {
  .title {
    font-size: 54px;
  }

  .sub {
    font-size: 24px;
  }
}
