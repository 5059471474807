.container {
  max-width: 750px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 8px;
  padding-right: 8px;
}

.large {
  max-width: 816px;
}

.unrestricted {
  max-width: none;
}

.container:before,
.container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
